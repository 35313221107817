<template>
  <LazyBranchTabs v-if="tabbedBranch" :branch="branch" />
  <LazyBranchHero :branch="branch" :page="page" :component="component" />
  <LazyBranchFeatures :branch="branch" :page="page" />

  <br-component component="top1" />

  <LazyBranchMembers :branch="branch" :page="page" />

  <br-component component="top2" />

  <section ref="branches" class="min-h-[350px] py-lg">
    <LazyBranchNearest
      v-if="loadNearest && branch"
      :lat="Number(branch[0].coordinates.lat)"
      :lng="Number(branch[0].coordinates.lng)"
      :current-branch-id="Number(branch[0].id)"
    />
  </section>
  <br-component component="top3" />
</template>

<script setup lang="ts">
import type { BranchDetailsProps } from "./branchDetailsTypes";

const props = defineProps<BranchDetailsProps>();

const tabbedBranch = ref<boolean>(false);
const intersected = ref<boolean>(false);
const loadNearest = ref<boolean>(false);

const branches = ref<Element>();

const { branch, page, component } = toRefs(props);

const loadNearestBranches = () => {
  loadNearest.value = true;
};

if (branch.value) {
  switch (branch.value[0]?.type) {
    case "home":
      branch.value[0]?.parent ? (tabbedBranch.value = true) : (tabbedBranch.value = false);
      break;
    case "build":
      branch.value[0]?.children && branch.value[0]?.children.length
        ? (tabbedBranch.value = true)
        : (tabbedBranch.value = false);

      break;
    default:
      tabbedBranch.value = false;
  }
}

onMounted(async () => {
  const lazyComponent = new IntersectionObserver((entries) => {
    const component = entries[0];

    if (component.isIntersecting) {
      intersected.value = true;
      loadNearestBranches();

      lazyComponent.disconnect();
    }
  });

  if (branches.value) {
    lazyComponent.observe(branches.value);
  }
});
</script>
