<template>
  <br-component component="main">
    <template #default="{ page, component }">
      <BranchDetails :page="page" :component="component" :branch="branch" v-if="branch" />
    </template>
  </br-component>
</template>

<script setup lang="ts">
import { useRoute } from "vue-router";
import useBranches from "../../composables/useBranches";
import { useHandleError } from "../../composables/useHandleError";
import { ref } from "vue";

useHead({
  bodyAttrs: {
    class: "bg-neutral-lighter",
  },
});

import type { Branch } from "@/composables/useBranches";
import type { Maybe } from "@vue-storefront/unified-data-model";

const route = useRoute();

const branch = ref<Maybe<Branch[]>>(null);

const { fetchBranchBySlug } = useBranches();

try {
  branch.value = await fetchBranchBySlug(route.params.branch as string);
} catch (error) {
  if (!branch.value) {
    useHandleError({
      status: 404,
      message: (error as any).message,
      statusMessage: (error as any).statusMessage,
      fatal: false,
    });
  }
}
</script>
